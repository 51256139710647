// // Typography
// // Fonts

@font-face {
  font-family: "Poppins-Regular";
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("../assets/fonts/dm-sans/DMSans-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "DMSans-Medium";
  src: url("../assets/fonts/dm-sans/DMSans-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "DMSans-Bold";
  src: url("../assets/fonts/dm-sans/DMSans-Bold.ttf") format("truetype");
  font-display: swap;
}


// // Sizes

.poppins-regular{
  font-family: 'Poppins-Regular';
}

.poppins-medium{
  font-family: 'Poppins-Medium';
  font-weight: 500;
}

.poppins-semibold{
  font-family: 'Poppins-SemiBold';
}
.poppins-bold{
  font-family: 'Poppins-Bold';
}

.dm-sans-regular{
  font-family: 'DMSans-Regular';
}
.dm-sans-medium{
  font-family: 'DMSans-Medium';
}

.dm-sans-bold{
  font-family: 'DMSans-Bold';
}


// Sizes

$base-font-size: 12px;
$base-line-height: 1;

.special-font {
  font-family: 'DMSans-Regular';
}

.typo-base {
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 400;
  font-family: 'DMSans-Regular';
}

.typo-heading-1 {
  @extend .special-font;
  font-size: 1.5rem;
  line-height: $base-line-height * 2;
  font-weight: 400;
}

.typo-heading-2 {
  @extend .special-font;
  font-size: 1.25rem;
  line-height: $base-line-height * 2;
  font-weight: 400;
  font-family: "Comfortaa", sans-serif;
}

.typo-heading-3 {
  font-size: 1.125rem;
  line-height: $base-line-height * 2;
  font-weight: 400;
  font-family: "Comfortaa", sans-serif;
}

.typo-heading-4 {
  @extend .special-font;
  font-size: 1rem;
  line-height: $base-line-height;
  font-weight: 600;
  font-family: "Comfortaa", sans-serif;
}

.typo-heading-5 {
  @extend .special-font;
  font-size: 1.125rem;
  line-height: $base-line-height;
  font-weight: 600;
  font-family: "Interstate_Regular", sans-serif;
}
