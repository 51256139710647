// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import "~@angular/material/theming";
@import "./typography.scss";
@import "./theme/material.scss";
@import "./helper.scss";
@import "./custom.scss";
// /* Custom css for formly-fields */
// @import "./formly-css/formly-fields.css";
// /* Table css */
// @import "./table-css/table.scss";
// // Custom Search field
// @import "./search-field-css/search-field.scss";
// /* C3 Charts */
// @import "../../node_modules/c3/c3.min.css";
// /* Task review css */
// @import "./task-css/task-review.scss";
// /* Task upload scss */
// @import "./task-css/task-upload.scss";
// /* Summary scss */
// @import "./summary-scss/summary.scss";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $app-primary: mat-palette($mdc-theme-primary, 900);
// $app-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
// $app-warn: mat-palette($mat-deep-orange);
// Create the theme object (a Sass map containing all of the palettes).
// $app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($app-theme);
//@include mat-button-theme($app-theme);
// // Dark theme
// $dark-primary: mat-palette($mat-grey, 900);
// $dark-accent: mat-palette($mat-amber);
// $dark-warn: mat-palette($mat-deep-orange);
// $dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);
.dark-theme {
    // @include angular-material-theme($dark-theme);
}

/* You can add global styles to this file, and also import other style files */

//variables
$scrollbar-track-color: #ffffff00;
$scrollbar-track-hover-color: #ffffff;
$scrollbar-thumb-color: #ffb548;
//Common Dialog
$common-dialog-shadow-color: #00000061;

html,
body {
    height: 100%;
    font-family: "DMSans-Regular";
    background: #f5f5f5;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    // @extend .typo-base;
    @include scrollbars(7px, 30px, $scrollbar-thumb-color, $scrollbar-track-color);
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        scroll-behavior: smooth;
    }
    ::-webkit-scrollbar-track {
        background: $scrollbar-track-color;
        border-radius: 30px;
        transition: 0.3s;
    }
    ::-webkit-scrollbar-track:hover {
        background: $scrollbar-track-hover-color;
        transition: 0.3s;
    }
    ::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
        border-radius: 30px;
    }
    // Global Css for Toolbar
    .primary-toolbar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        background-color: #eef7fb;
        padding: 10px;
        box-shadow: 0px 3px 6px #00000029;
        .toolbar-title {
            font-size: 18px;
            background-color: transparent;
            color: #00629d;
        }
        .toolbar-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            button,
            span,
            div {
                margin-left: 10px;
            }
        }
    }
}

.cdk-global-scrollblock {
    overflow-y: hidden !important;
}

// anchor css
a {
    text-decoration: none;
    color: inherit;
    outline: none;
}

.form-label {
    font-size: 12px;
    font-family: "OpenSans_Bold";
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
.e-toast-icon.e-danger {
    background-image: url("../assets/custom/error-toast-icon.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.e-toast-icon.e-success {
    background-image: url("../assets/custom/success-toast-icon.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

::-webkit-input-placeholder {
    font-style: italic;
    color: #8e8e8e;
    font-family: "DMSans-Regular";
    opacity: 1; /* Firefox */
}

::placeholder {
    color: #8e8e8e;
    font-style: italic;
    font-family: "DMSans-Regular";
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8e8e8e;
    font-style: italic;
    font-family: "DMSans-Regular";
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8e8e8e;
    font-style: italic;
    font-family: "DMSans-Regular";
}

.e-datepicker.e-popup, .e-daterangepicker.e-popup {
    font-family: "Poppins-SemiBold";
    color: #000000;
}

.e-today {
    .e-day {
      //  border-color:#00629d !important;
      //  color: #ffd08b !important;
    }
}

.e-selected {
    .e-day {
        background-color: #00629d !important;
        color: #fff !important;
    }
}



.e-btn.e-today {
    color: #00629d !important;
    &:hover,
    &:focus {
        background-color: #333 !important;
        color: #fff !important;
    }
}

lightbox{
    position: absolute !important;
    max-width: 50vw !important;
    max-height: 70vh !important;
    top: 15% !important;
    left: 15% !important;
}