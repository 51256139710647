.mat-form-field {
    width: 100%;
}
.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: rgb(175, 175, 175) !important;
}
.mat-form-field-prefix {
    top: 0px !important;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background-color: #ffa400 !important;
}

.e-dialog {
    .e-dlg-overlay {
        background-color: slategray !important;
        opacity: 0.6 !important;
    }

    .e-dlg-header-content {
      //  background: #eef7fb !important;
        padding: 10px 18px 10px 18px !important;
    }

    .e-dlg-header {
        text-align: left !important;
        font-family: "Montserrat-Bold";
        letter-spacing: 0px !important;
        color: #00629d !important;
        opacity: 1 !important;
        font-size: 18px !important;
    }

    .e-dlg-content {
        padding-top: 15px !important;

        textarea.e-input,
        .e-float-input textarea,
        .e-float-input.e-control-wrapper textarea,
        .e-input-group textarea,
        .e-input-group.e-control-wrapper textarea {
            resize: none !important;
        }

        .label {
            font-size: 14px !important;
            font-family: "Open-Sans-Regular";
            margin-bottom: 10px !important;
        }
    }

    .e-footer-content {
        padding: 15px 0px 0px 0px !important;
    }
}

.pointer {
    cursor: pointer;
}

.e-tab {
    .e-tab-header {
        .e-indicator {
            background: transparent !important;
            margin-left: 0px;
        }

        .e-toolbar-item {
            .e-tab-wrap {
                padding: 0px 12px;
            }
            .e-tab-text {
                color: #000000 !important;
                text-transform: none;
                font-size: 15px;
                font-family: "DMSans-Medium";
            }
        }

        .e-toolbar-item.e-active .e-tab-text {
            color: #fff !important;
            font-family: 'DMSans-Bold';
        }
    }

    .e-content .e-item {
        padding-top: 20px !important; // TODO Custom css file should be reviewed.... Its css getting affected in the whole application
        background: transparent !important;
    }
}
